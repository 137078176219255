/* Define the font family */
.font-source-sans-3 {
    font-family: 'Source Sans 3', sans-serif;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    justify-content: flex-start;
    text-align: center;
    min-height: 100vh;
}

.getOffLabel{
    font-size: 10px;
    font-family: 'Source Sans 3';
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
}

.contentContainer {
    display: flex;
    width: 80%;
    flex-direction: column;
    align-items: center;
}

.animationDiv {
    height: 100px;
    width: 200px;
    cursor: pointer;
}

.testimonialsDiv {
    display: flex;
    flex-direction: row;
}

.ratingDiv {
    display: flex;
    flex-direction: row;
}
.testimonialsDivRight{
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
}

.characterImage {
    max-width: 130px;
}

.toolContentDiv {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.toolContentHeader {
    display: flex;
    align-items: start;
    justify-content: start;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

}

.meetingNotesTool {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    border-radius: 17px;
    flex-direction: column;
    background-color: black;
    filter: none;
    /* Ensure no filters are applied */
    background-repeat: no-repeat;
    /* Path relative to the CSS file */
    background-size: fill;
    align-items: center;
    background-position: center;
    height: 645px;
    padding-bottom: 0px;
}

.subButtonLabel {
    font-size: 10px;
    margin-top: 4px;
}

.statusTag {
    color: #60FFAC;
    text-align: center;
    font-family: 'Source Sans 3';
    font-size: 18px;
    margin-top: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.h1 {
    color: #FFF;
    text-align: center;
    font-size: 36px;
    font-family: 'Source Sans 3';
    font-style: normal;
    width: 70%;
   text-align: center;
    font-weight: 700;
    line-height: normal;
    margin-top: -15px;
    margin-bottom: -15px;

}

.featuredOnDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
}


.logoImages {
    height: 28px;
    cursor: pointer;
    filter: saturate(0);
    opacity: 70%;
}

.logoImages:hover {
    filter: saturate(1); 
    opacity: 100%;
}



.p {
    color: rgba(255, 255, 255, 0.60);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-family: 'Source Sans 3';
    font-weight: 400;
    line-height: normal;
    width: 45%;
    margin-bottom: 10px;    
}

.seperator {
    color: rgba(255, 255, 255, 0.60);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-family: 'Source Sans 3';
    font-weight: 400;
    line-height: normal;
    width: 5%;
   
    margin-bottom: 10px;    
}

.pHighlight {
    font-weight: bold;
    color: rgba(255, 255, 255, 0.8); /* White color for the highlighted text */
  }

.ctaDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.buttonTryDemo {
    color: #FFF;
    background-color: #5046E5;
    padding: 10px;
    border-radius: 7px;
    border: none;
    font-size: 16px;
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}

.buttonTryDemo:hover {
    color: #FFF;
    background-color: #5046E5;
    padding: 10px;
    border-radius: 7px;
    border: none;
    font-size: 16px;
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;

}

.buttonGetTool {
    color: #FFF;
    background-color: #272727;
    padding: 10px;
    border-radius: 7px;
    border: none;
    font-size: 16px;
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}

.video {
    width: 940px;
}

.headerDiv {
    width: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.heading {
    font-size: 3rem;
    line-height: 1.5;
    margin-bottom: 20px;
    font-family: 'Inter';
    font-family: 'Source Sans 3';
    font-weight: 700;
}

.subHeading {
    font-size: 22px;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 22px;
    font-family: 'Source Sans 3';
    font-weight: 500;
    color: rgba(23, 23, 23, 0.6)
}

.highlight {
    background-color: rgba(80, 70, 229, 0.2);
    /* Light blue background with some transparency */

}

.buttonDemo {
    border-radius: 4px;
    background: #5046E5;
    width: 168px;
    height: 44px;
    flex-shrink: 0;
    border: none;
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
}

.options {
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background-color: none;
    padding: 20px;
    width: 60%;
    height: 100%;
    margin-top: 40px;
    border-radius: 22px;
}

.option {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    background-color: #FAFBFC;
    border-radius: 9px;
    border: 1px solid #FAFBFC;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    width: 70%;
    padding: 10px;
}

.option:hover {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    background-color: #F3F4F6;
    border-radius: 9px;
    border: 1px solid #FAFBFC;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    transition: transform 0.2s ease-in-out;
    width: 70%;
    padding: 10px;
    cursor: pointer;
}

.optionContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    margin-left: 5px;
}

.inactiveOptionContent {
    display: flex;
    opacity: 0.4;
    flex-direction: column;
    width: 100%;
    text-align: left;
    margin-left: 5px;
}

.h2 {
    font-family: 'Inter';
    font-size: 16px;
}

.meetingNotesDiv {
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: 40px;
}

.descriptiveText {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    color: #575757;
    width: 70%
}

.contentHeader {
    display: flex;
    justify-content: start;
    align-items: start;
    text-align: left;
}


.iconContainer {
    display: flex;
    justify-content: start;
    align-items: start;
    width: 20px;
    background-color: #4285F4;
    /* Blue background */
    border-radius: 20px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}


.iconContainer:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #0E68FC;
    /* Blue background */
    border-radius: 20px;
    scale: 1.01;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    /* Add shadow */
}


.icon {
    width: 20px;
    height: 15px;
}

.inactiveIcon {
    width: 20px;
    height: 15px;
    opacity: 0.3;
}

.optionHeading {
    margin-top: 0px;
    font-size: 16px;
    font-family: 'Inter';
    font-weight: 600;
    width: 80%;
    color: black;
}

.option:hover .optionHeading {
    color: #5046E5;
}

.optionSubHeading {
    margin-top: 4px;
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 500;
    width: 80%;
    color: rgba(0, 0, 0, 0.5);
}


.inactiveLabel {
    margin-top: 10px;
    font-size: 16px;
    font-family: 'Inter';
    font-weight: 700;
    width: 80%;
    color: rgba(0, 0, 0, 0.213);
}

.inactiveOption .iconContainer {
    background-color: #e0e0e0;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.213);
}

/* Responsive Design */
@media (max-width: 1200px) {
    .characterImage {
        margin-left: -28%;
    }

    .options {
        width: 70%;
    }
}

@media (max-width: 992px) {
    .characterImage {
        max-width: 100px;
        margin-left: -30%;
    }

    .heading {
        font-size: 24px;
    }

    .options {
        width: 80%;
    }
}

@media (max-width: 768px) {
    .container {
        width: 80%;
    }

    .characterImage {
        display: none;
    }

    .heading {
        font-size: 22px;
    }

    .options {
        flex-direction: column;
        width: 90%;
        height: auto;
    }

    .option {
        margin-bottom: 20px;
    }

    .iconContainer {
        width: 90px;
        height: 90px;
    }

    .icon {
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 576px) {
    .characterImage {
        max-width: 70px;
        margin-left: -5%;
    }

    .heading {
        font-size: 20px;
    }

    .options {
        width: 100%;
    }

    .iconContainer {
        width: 80px;
        height: 80px;
    }

    .icon {
        width: 35px;
        height: 35px;
    }

    .label,
    .inactiveLabel {
        font-size: 14px;
    }
}
/* General container styling */
.notesArchiveContainer {
    display: flex;
    height: 95vh;
    width: 100%;
    background-color: white;
    overflow: hidden;
    flex-direction: row;
    align-items: start;
}

.notesArchiveContentDiv {
    display: flex;
    flex-direction: row;
    padding: 60px;
    margin-left: 40px;
    margin-top: 10px;
    width: 80%;
    height: 100%;
    overflow: hidden;
}

.notesContainer{
    display: flex;
   margin-top: 30px;
   align-items: start;
   justify-content: start;
  width: 100%;
}

.sidebar {
    width: 30%;
    height: 100%;
    margin-left: 0px;
    background-color: transparent;
    padding-right: 10px;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
}

.foldersHeader {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #828282;
    font-size: 12px;
    margin-bottom: 10px;
    justify-content: space-between;
}

.toggleIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.folderList {
  width: 100%;
}

.aiActionItem {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    background-color: #fff;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 15px;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
}

.aiActionItem:hover {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    background-color: #eaeaea;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 15px;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
}

.folderItem {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    background-color: #fff;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
}

.folderItem:hover,
.folderItemSelected {
    background-color: #eaeaea;
}

.folderIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}


.folderIconLarge {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
}

.mainMeetingDiv{
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    width: 200vh;
}

.mainContent {
    width: 40%;
    padding: 0px;
    height: 73vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.buttonDownload {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .buttonDownload:hover {
    background-color: #0056b3;
  }

.buttonGoToMeetingNotes {
    position: absolute;
    border-radius: 10px;
    font-family: 'Inter';
    font-weight: 600;
    cursor: pointer;
    bottom: 0px;
    left: 43.5%;
    right: 43.5%;
    padding: 10px 20px;
    background-color: #DCD8F8;
    color: black;
    border: none;
    border-radius: 5
  }
  .buttonShowIntro{
    margin-top: 20px;
  }
  .buttonGoToMeetingNotes:hover {
    position: absolute;
    border-radius: 10px;
    font-family: 'Inter';
    font-weight: 600;
    cursor: pointer;
    bottom: 0px;
    left: 43.5%;
    right: 43.5%;
    padding: 10px 20px;
    background-color: #CAC6F6;
    color: black;
    border: none;
    border-radius: 5
  }




/* Search bar styling */
.searchContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: none;
    background-color: #FDFCFD;
    height: 30px;
    border: 1px solid #E4E3E4;
    border-radius: 5px;
}

.searchIcon {
    position: absolute;
    left: 4%;
    height: 14px; /* Adjust the size as needed */
    pointer-events: none; /* Ensure the icon is not clickable */
    opacity: 40%;
}

.searchBar::placeholder {
    color: #A2A1A2;
    font-weight: 600;
}

.searchBar:focus {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    border: none;
    outline: none;
}

.searchBar {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    border: none;
    outline: none;
    margin-left: 14%;
    font-size: 14px;
}

/* Meeting list styling */
.weekMeetings {
    width: 20%;
}

.weekHeader {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    width: 100%;
    padding-left: 30px;
    margin-left: 0px;
    background-color: white;
     margin-top: 0px;
    padding-bottom: 10px;
    position: fixed;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.meetingList {
    list-style: none;
    padding: 0;
    margin-top: 50px;
    padding-left: 20px;
    width: 200px;
}

.meetingListItem {
    display: flex;
    font-size: 12px;
    border: none;
    box-shadow: none;
    justify-content: space-between;
    padding: 10px;
    background-color: transparent;
    border-radius: 9px;
    transition: transform 0.2s ease-in-out;
    margin-bottom: 10px;
    cursor: pointer;
}

.meetingListItem:hover {
    background-color: #F3F4F6;
}

.meetingListItemSelected {
    background-color: #f0f0f0;
}

.meetingSummary {
    font-size: 14px;
    font-weight: 500;
}

.meetingDate {
    font-size: 12px;
    color: #888;
}

.menuDots {
    cursor: pointer;
}

.dropdownMenu {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.dropdownItem {
    padding: 10px;
    cursor: pointer;
}

.dropdownItem.danger {
    color: red;
}

/* All folders grid styling */
.allFolders {
    width: 80%;
    margin-left: 30px;
}

.allFoldersHeader {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    margin-bottom: 10px;
}

.folderGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.folderGridItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.folderGridItem:hover {
    transform: scale(1.05);
}

/* Overlay content styling */
.meetingNameHeader {
    margin-left: 52px;
    margin-top: 0px;
}

.toolbar {
  position: fixed;
  display: flex;
  padding-top: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  background-color: white;
  z-index: 1000;
}

.toolbarContent {
  display: flex;
  flex-direction: row;
  width: 80%;
  
}

.leftSection {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-start;
}

.dropdownToggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3px;
  font-size: 14px;
  border-radius: 5px;
  line-height: 12px;
  transition: background-color 0.3s;
}

.dropdownToggle:hover {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3px;
  font-size: 14px;
  background-color: #F3F3F3;
  border-radius: 5px;
  line-height: 12px;
  transition: background-color 0.3s;
}

.dropdownContent {
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.15);
  border-radius: 10px;
  margin-top: 140px;
  width: 150px;
  padding: 7px;
  margin-left: -3px;
}

.dropdownItem {
  padding: 4px 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdownDivider {
  height: 1px;
  background-color: #E8E8E8;
  margin: 5px 0;
  opacity: 0.5;
}

.centerSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;
}

.icon {
  height: 16px;
  margin-right: 5px;
}

.centerText {
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  margin-right: 3px;
}

.lockIcon {
  height: 12px;
  margin-right: 5px;
}

.encryptionText {
  color: #2ED87F;
  font-family: Inter;
  font-weight: 600;
  font-size: 12px;
  line-height: 0px;
}

.templateButton {
  font-weight: 600;
  font-family: Inter;
  background-color: transparent;
  color: black;
  border: none;
  border-radius: 4px;
  padding: 0px 10px;
  cursor: pointer;
}

.rightSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;

}

.feedbackButton {
  background-color: transparent;
  border: none;
  font-family: Inter;
  font-size: 12px;
  margin-right: 10px;
  cursor: pointer;
}

.userDropdownToggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3px;
  font-size: 14px;
  border-radius: 5px;
  line-height: 12px;
  transition: background-color 0.3s;
}

.userDropdownContent {
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.15);
  border-radius: 10px;
  margin-top: 40px;
  width: 150px;
  padding: 7px;
  right: 10px;
}

@media (max-width: 1024px) {
  .centerSection {
      display: none; /* Hide the center section on mobile */
  }
}

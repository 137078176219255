/* animations.css */

/* Initial state for entering from the right */
.slide-enter {
  position: absolute;
  width: 100%;
  opacity: 0;
  transform: translateX(40%);
}

/* Animation state for entering from the right */
.slide-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 200ms ease-out, transform 200ms ease-out;
}

/* Initial state for exiting to the left */
.slide-exit {
  position: absolute;
  width: 100%;
  opacity: 1;
  transform: translateX(0%);
}

/* Animation state for exiting to the left */
.slide-exit-active {
  opacity: 0;
  transform: translateX(-20%);
  transition: opacity 300ms ease-out, transform 300ms ease-out;
}

/* Initial state for entering from the left */
.slide-enter-reverse {
  position: absolute;
  width: 100%;
  opacity: 0;
  transform: translateX(100%);
}

/* Animation state for entering from the left */
.slide-enter-active-reverse {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms ease-out, transform 300ms ease-out;
}

/* Initial state for exiting to the right */
.slide-exit-reverse {
  position: absolute;
  width: 100%;
  opacity: 1;
  transform: translateX(0);
}

/* Animation state for exiting to the right */
.slide-exit-active-reverse {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms ease-out, transform 300ms ease-out;
}

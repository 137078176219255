.container {
  display: flex;
  flex-direction: row;
  align-items: start;
  height: 96vh;
  width: 100%;
  background-color: #fff;
  font-family: 'Inter';
  overflow-y: hidden;
  transition: transform 0.3s ease-in-out;
}

.buttonGoToArchive {
  position: absolute;
  border-radius: 10px;
  font-family: 'Inter';
  font-weight: 600;
  cursor: pointer;
  bottom: 0px;
  left: 43.5%;
  right: 43.5%;
  padding: 10px 20px;
  background-color: #DCD8F8;
  color: black;
  border: none;
  border-radius: 5
}

.buttonGoToArchive:hover {
  position: absolute;
  border-radius: 10px;
  font-family: 'Inter';
  font-weight: 600;
  cursor: pointer;
  bottom: 0px;
  left: 43.5%;
  right: 43.5%;
  padding: 10px 20px;
  background-color: #CAC6F6;
  color: black;
  border: none;
  border-radius: 5
}

.contentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: start;
  padding: 40px;
  margin-left: 15%;
  margin-right: 0%;
  margin-top: 10px;
  width: 80%;
  height: 100%;
  overflow: hidden;
}

.selectedMeeting {
  color: grey;
  font-family: 'Inter';
  line-height: 0px;
  font-weight: 500;
  font-size: 12px;
  margin-left: 20px;
  margin-top: 10px;
  display: flex;
  border: none;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.editableTitle {
  margin-top: 35px;
  height:10px;
  min-width: 400px;
  font-size: 32px;
  outline: none;
  color: black;
}

.editableTitle.placeholder {
  color: #D4D2D2;
}

.editableDate {
  min-width: 400px;
}

.react-datepicker-wrapper {
  display: inline-block;
  /* Ensure it does not take up the full width of its container */
  min-width: 450px;
  /* Set a minimum width to prevent collapsing */
}

.datePickerWrapper {
  display: flex;
  margin-left: -10px;
  align-items: center;
  gap: 0px;
  width: 100%;
  flex-wrap: wrap;
  /* Allow wrapping */
}

.futureTag {
  padding: 0px 5px;
  margin-left: 10px;
  color: rgba(255, 159, 11, 1);
  background-color: rgba(255, 159, 11, 0.1);
}

.datePickerWrapper input {
  flex: 1;
  min-width: 45px;
  max-width: 200px;
  text-align: left;
  /* Center align text */
  cursor: pointer;
}

.react-datepicker__input-container {
  min-width: 200px;
}



.prepNotesHeading {
  font-family: 'Inter';
  font-weight: 700;
  color: rgba(55, 53, 47, 0.40);
}

.inMeetingHeading {
  font-family: 'Inter';
  font-weight: 700;
  color: black;
  margin-left: 20px;
}

.flexContainer {
  display: flex;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  margin-top: 0px;

}

.meetingNotesContainer {
  margin-left: 20px;
  min-width: 280px;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
}


.meetingNotesEditor {
  height: 100%;
  width: 100%;
  min-width: 300px;
  margin-bottom: 0px;
  margin-left: -33px;
  z-index: 0;
  font-size: 14px;
  box-sizing: border-box;
  /* Ensure padding and border are included in the width and height */
}


.skeleton {
  width: 10%;
  height: 0%;
  background-color: #e0e0e0;
  animation: skeleton-loading 1.2s linear infinite alternate;
}

.addMeetingDiv {
  position: relative;
  margin-bottom: 10px;
  margin-left: 40px;
  border-bottom: 1px solid #F2F2F2;
  padding-bottom: 10px;
  width: 100%;
  flex-direction: row;
  display: flex;
}

.slide-enter {
  transform: translateX(100%);
  opacity: 0;
}

.slide-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}

.slide-exit {
  transform: translateX(0);
  opacity: 1;
}

.slide-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}


.buttonAddMeeting {
  background-color: rgba(80, 70, 229, 0.1);
  color: rgba(80, 70, 229, 0.6);
  display: flex;
  /* Use flexbox */
  align-items: center;
  /* Center items vertically */
  justify-content: center;
  /* Center items horizontally */
  border: none;
  padding: 10px 10px;
  font-size: 12px;
  font-family: 'Inter';
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.buttonAddMeeting:hover {
  background-color: rgba(80, 70, 229, 0.2);
  color: rgba(80, 70, 229, 0.7);
  display: flex;
  /* Use flexbox */
  align-items: center;
  /* Center items vertically */
  justify-content: center;
  /* Center items horizontally */
  border: none;
  padding: 10px 10px;
  font-size: 12px;
  font-family: 'Inter';
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px;
}

.meetingDropdown {
  background-color: #F3F1F1;
  border: none;
  outline: none;
  border-radius: 6px;
  padding: 10px 10px;
  margin-left: 10px;
}

.buttonAddMeeting img {
  margin-right: 5px;
  /* Add some space between the image and the text */
}





@keyframes skeleton-loading {
  0% {
    background-color: #e0e0e0;
  }

  100% {
    background-color: #c0c0c0;
  }
}

/* Make the selector more specific to ensure it overrides default styles */
.customHandle {
  position: absolute;
  right: 0;
  top: 0;
  width: 10px;
  height: 100%;
  background: #F4F4F4;
  cursor: ew-resize;
}
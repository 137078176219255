.intro {
    position: absolute;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 104%;
    margin-top: -20px;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    align-items: center;
    justify-content: center;
  }
  
  .content {
    background-color: white;
    border-radius: 28px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 40px;
    text-align: center;
    width: 400px;
    max-width: 90%;
  }
  
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 0px;
    padding-top: 0px;
  }
  
  .encryptionNote {
    color: rgba(46, 216, 127, 0.80);
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
    
    margin-left: 5px;
  }
  
  .icon {
    height: 12px;
  }

  .iconFolder {
    height: 81.49px;
    margin-top: 28px;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .description {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .actions {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .primaryButton {
    background-color: #6A5ACD;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .secondaryButton {
    background-color: transparent;
    color: #6A5ACD;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .checkboxLabel {
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
  }
  
  .checkboxLabel input {
    margin-right: 10px;
  }

  .topHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0px;
  }
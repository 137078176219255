.timelineContainer {
  display: flex;
  width: 350px; /* Fixed width for the timeline */
  height: 100vh; /* Full viewport height */
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #F9F8F8;
  border-right: 2px solid #ddd;
  position: relative;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.timelineContentContainer {
  padding-left: 70px;
}

.header {
  margin-top: 30px;
  position: fixed;
  z-index: 1;
  display: flex;
  width: 100%;
  cursor: pointer;
}

.datePicker {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: red;
}

.timelineContainer::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.timeLabels {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 20%;
  top: 0;
  width: 50px; /* Width of the time label container */
}

.timeLabel {
  height: 60px; /* Each hour is 60px tall */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  color: #E0DFDF;
  font-family: 'Inter';
  font-weight: 600;
  padding-right: 10px;
  box-sizing: border-box;
  position: relative;
}

.timeLabel::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  width: calc(100vw - 50px); /* Adjust based on the total width */
  height: 1px;
  background: #ddd; /* Line color */
  transform: translateY(-50%);
}

.timeline {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; /* Ensure the timeline has a height to enable scrolling */
  position: relative;
  padding-left: 100px; /* Adjust padding to create space for the time labels */
}

.meetingCard {
  position: absolute;
  left: 50px;
  right: 10px;
  padding: 5px;
  background-color: #252525;
  color: white;
  border-radius: 5px;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: background-color 0.2s ease, transform 0.1s ease;
}

.meetingCard:hover {
  background-color: #545454;
}

.meetingCard.active {
  background-color: #D0EDDD;
  border: 1px solid #2ED87F;
  color: black;
}

.currentTimeMarker {
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #FE4D2C;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: start;
}

.currentTime {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 12px;
  border-right: 2px solid #FE4D2C;
  margin-top: 15px;
  margin-left: 0px;
  background-color: white;
  padding-right: 5px;
  padding-left: 0px;
  color: #FE4D2C;
  transform: translateY(-50%);
  position: absolute;
}
/* Overlay.module.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .overlayContent {
    background: white;
    padding: 20px;
    border-radius: 25px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
    min-width: 60%;
    min-height: 60%;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .closeButton {
    position: relative;
    top: 1rem;
    margin-left: 96%;
    right: 0,5rem;
    line-height: 0px;
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
  }
  


.button {
    background: rgba(255, 159, 11, 0.10);
    color: rgba(80, 70, 229, 0.6);
    display: flex;
    height: 100%;
    /* Use flexbox */
    align-items: center;
    /* Center items vertically */
    justify-content: center;
    /* Center items horizontally */
    border: none;
    padding: 10px 10px;
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 600;
    margin-left: 0px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .button:hover {
    background: rgba(255, 159, 11, 0.30);
  }


  /* AIActionsStyles.module.css */
.aiGeneratedLabel {
    background: rgba(255, 159, 11, 0.10);
    color: white;
    padding: 2px 4px;
    border-radius: 3px;
    display: inline-block;
    margin-right: 5px;
  }
  
.inputContainer{
    display: flex;
    padding: 5px;
    flex-direction: row;
    background: rgba(255, 159, 11, 0.10);
    color: rgba(255, 159, 11, 1);
    border-radius: 5px;
    margin-left: 10px;
}

.promptInput {
    background-color: transparent;
    color: rgba(255, 159, 11, 1);
    outline: none;
    border: none;
    font-weight: 600;
    font-style: normal;
    margin-left: 3px;
}

.promptInput::placeholder {
    color: rgba(255, 159, 11, 0.7); /* Adjust the color to match your preference */
    font-weight: 400; /* Make the font-weight same as input */
    font-style: normal; /* Ensure the font-style matches the input */
  }
.footer {
    background-color: white;
    color: black;
    border-top: 1px solid #EBEBEB;
    margin-top: 60px;
    padding: 20px 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', sans-serif;
    bottom: 0;
}

.footerContent {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    align-items: start;
    width: 100%;
}

.companyInfo {
    text-align: left;
}

.companyInfo h2 {
    margin: 0;
    font-size: 24px;
}

.companyInfo p {
    margin: 5px 0 0;
    font-size: 14px;
}

.links {
    display: flex;
    gap: 20px;
}

.links a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
}

.links a:hover {
    text-decoration: underline;
}

.socialMedia {
    display: flex;
    gap: 10px;
}

.socialMedia a {
    display: flex;
    align-items: center;
}

.socialMedia img {
    width: 24px;
    height: 24px;
}

/* lightTheme.css */
.bn-container[data-color-scheme] {
    --bn-colors-editor-text: #3f3f3f;
    --bn-colors-editor-background: #ffffff;
    --bn-colors-menu-text: #3f3f3f;
    --bn-colors-menu-background: #ffffff;
    --bn-colors-tooltip-text: #3f3f3f;
    --bn-colors-tooltip-background: #efefef;
    --bn-colors-hovered-text: #3f3f3f;
    --bn-colors-hovered-background: #efefef;
    --bn-colors-selected-text: #ffffff;
    --bn-colors-selected-background: #3f3f3f;
    --bn-colors-disabled-text: #afafaf;
    --bn-colors-disabled-background: #efefef;
    --bn-colors-shadow: #cfcfcf;
    --bn-colors-border: #efefef;
    --bn-colors-side-menu: #cfcfcf;
    --bn-colors-highlights-gray-text: #9b9a97;
    --bn-colors-highlights-gray-background: #ebeced;
    --bn-colors-highlights-brown-text: #64473a;
    --bn-colors-highlights-brown-background: #e9e5e3;
    --bn-colors-highlights-red-text: #e03e3e;
    --bn-colors-highlights-red-background: #fbe4e4;
    --bn-colors-highlights-orange-text: #d9730d;
    --bn-colors-highlights-orange-background: #f6e9d9;
    --bn-colors-highlights-yellow-text: #dfab01;
    --bn-colors-highlights-yellow-background: #fbf3db;
    --bn-colors-highlights-green-text: #4d6461;
    --bn-colors-highlights-green-background: #ddedea;
    --bn-colors-highlights-blue-text: #0b6e99;
    --bn-colors-highlights-blue-background: #ddebf1;
    --bn-colors-highlights-purple-text: #6940a5;
    --bn-colors-highlights-purple-background: #eae4f2;
    --bn-colors-highlights-pink-text: #ad1a72;
    --bn-colors-highlights-pink-background: #f4dfeb;
    --bn-font-family: "Inter", "SF Pro Display", -apple-system, BlinkMacSystemFont, "Open Sans",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
    --bn-border-radius: 6px;
  }
  